import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Contact - Kimberly Plomp, PhD</title>
            <meta name="description" content="Contact information" />
            <meta name="keywords" content="contact,email,social media" />
        </Helmet>

        <BannerLanding title="Contact" subtitle="For any inquiries" />

    </Layout>
)

export default Landing
